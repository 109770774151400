import { ClusterColor } from './types';

/**
 * Propegation strategy for tessellation
 */
export enum StrategyOptions {
  'tournament' = 'tennis',
  'democracy' = 'democracy',
  'default' = 'democracy',
}

export const IS_TEST_ENV: boolean =
  process.env.REACT_APP_ENVIRONMENT !== 'prod';

/**
 * Required fields for Logs API Data Item
 */
export enum LogsApiSystemColumns {
  drillholeId = 'drillhole_id',
  fromDepth = 'depth_from',
  toDepth = 'depth_to',
  createdAt = 'created_at',
  createdBy = 'created_by',
  updatedAt = 'updated_at',
  updatedBy = 'updated_by',
  logId = 'log_id',
  _id = '_id',
}

export const clusterColorOptions: ClusterColor[] = [
  '#800000', // Cluster ? - Maroon
  '#4363d8', // Cluster 0 - Blue
  '#ffe119', // Cluster 1 - Yellow
  '#a9a9a9', // Cluster 2 - Grey
  '#e6194B', // Cluster 3 - Red
  '#3cb44b', // Cluster 4 - Green
  '#dcbeff', // Cluster 5 - Lavander
  '#f58231', // Cluster 6 - Orange
  '#9A6324', // Cluster 7 - Brown
  '#469990', // Cluster 8 - Teal
  '#42d4f4', // Cluster 9 - Cyan
  '#983020',
  '#7671dd',
  '#ffdf3d',
  '#b5b5b5',
  '#ee4861',
  '#6bbd4e',
  '#e3c0ff',
  '#f89048',
  '#ab753b',
  '#57ab93',
  '#71d6ff',
  '#af513d',
  '#9b81e3',
  '#ffde55',
  '#c0c0c0',
  '#f46777',
  '#8fc656',
  '#e9c3ff',
  '#fb9d5e',
  '#bc8751',
  '#6fbc94',
  '#98d7ff',
  '#c5705c',
  '#b993e9',
  '#ffdd6a',
  '#cccccc',
  '#f9828d',
  '#afcf60',
  '#efc5ff',
  '#fdab73',
  '#cd9a67',
  '#8ccc93',
  '#bad8ff',
  '#d98f7d',
  '#d4a6ef',
  '#ffdc7d',
  '#d8d8d8',
  '#fd9ba3',
  '#ccd76e',
  '#f4c8ff',
  '#ffb889',
  '#deae7e',
  '#addb90',
  '#d6daff',
  '#edaf9f',
  '#ebbaf6',
  '#ffdc8f',
  '#e4e4e4',
  '#ffb4ba',
  '#e6e07e',
  '#facbff',
  '#ffc59f',
  '#eec196',
  '#d2e98f',
  '#ecddff',
  '#ffcfc3',
  '#ffd0ff',
  '#ffdca0',
  '#f1f1f1',
  '#ffccd0',
  '#ffe990',
  '#ffceff',
  '#ffd2b5',
  '#ffd5ae',
  '#faf591',
  '#fce2ff',
  '#fab9ab',
  '#fbb8e9',
  '#f9c488',
  '#f1d4d4',
  '#fab6bb',
  '#fcce78',
  '#fbb6e9',
  '#f9bc9d',
  '#f9bf96',
  '#fad876',
  '#f6c8ed',
  '#f4a296',
  '#f79fd0',
  '#f4ac74',
  '#f0b8b8',
  '#f59fa5',
  '#f8b366',
  '#f89dcf',
  '#f3a687',
  '#f3a880',
  '#f8bb61',
  '#f2add6',
  '#ed8b82',
  '#f387b4',
];
